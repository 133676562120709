$form-floating-height: calc(3.5rem + 2px);
$form-floating-line-height: 1.25;
$form-floating-padding-x: 0.75rem;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem)
   translateX(0.15rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;

.container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   min-height: 100vh;

   background-color: var(--clr-bg);
   color: var(--on-bg);
}
.form {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   h2 {
      font-size: 5rem;
      font-weight: 700;
      margin-bottom: 1rem;
   }

   .group {
      position: relative;

      width: 100%;
      margin: 1rem;

      .input {
         padding: 1.25rem 1rem;
         border-radius: 0.375rem;
         border-style: solid;

         background-color: var(--clr-bg);
         color: var(--on-bg);

         width: 100%;

         &:focus,
         &:not(:placeholder-shown) {
            ~ .label {
               transform: translateY(-1rem) scale(0.75) translateX(-1rem);
            }
         }

         &::placeholder {
            color: transparent;
         }
      }

      .label {
         position: absolute;
         top: 0;
         left: 0;

         color: var(--on-bg);

         padding: 1.25rem 1rem;
      }
   }

   .button {
      width: 100%;
      padding: 1rem 0.75rem;
      margin: 1rem;

      font-size: 1.25rem;

      border-color: var(--clr-primary);
      border-style: solid;
      border-radius: 0.375rem;

      background-color: var(--clr-primary);
      color: var(--on-primary);

      cursor: pointer;

      &:hover {
         border-color: var(--clr-primary-dark);
         background-color: var(--clr-primary-dark);
      }
   }

   .altlinks {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
         margin: 0.25rem;
         text-decoration: underline;

         font-weight: 700;
         transition: color 0.1s ease-in-out;

         &:hover {
            color: var(--clr-primary);
         }
      }
   }
}
